interface Props {
  parent?: string;
  title: string;
}

export default function PageIntro({ parent, title }: Props) {
  return (
    <div className="relative flex h-[200px] w-full flex-col bg-gradient-to-tr from-blue-200 to-blue-50 md:h-[300px]">
      <img
        src="/img/page-intro.webp"
        className="absolute inset-0 block h-full w-full object-cover"
        width="1700"
        height="300"
        alt=""
      />
      <div className="fluid-container isolate mt-auto mb-6 lg:mb-10">
        <div className="relative inline-block">
          {parent && (
            <div className="text-sm font-bold uppercase text-blue md:text-lg">
              {parent}
            </div>
          )}
          <h1 className="max-w-2xl text-3xl font-black text-blue-800 md:text-4xl lg:text-5xl">
            {title}
          </h1>
          <div className="absolute inset-0 z-behind scale-150 bg-white blur-xl"></div>
        </div>
      </div>
    </div>
  );
}
