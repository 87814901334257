import { Menu } from "@headlessui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { forwardRef } from "react";

type ItemLinkProps = {
  href: string;
  children: React.ReactNode;
};

const itemClassNames =
  "group flex gap-x-3 px-6 py-3 transition hover:bg-blue-50 focus-visible:bg-blue-50 focus-visible:outline-none focus-visible:ring focus-visible:ring-inset focus-visible:ring-blue-400";

const ItemLink = forwardRef(function ItemLink(
  { href, children, ...rest }: ItemLinkProps,
) {
  const { asPath } = useRouter();
  return (
    <li>
      <Link href={href}
          aria-current={asPath === href ? "page" : undefined}
          className={`${itemClassNames} ${asPath === href ? "bg-blue-50" : ""}`}
          {...rest}
        >
          {children}
      </Link>
    </li>
  );
});

interface ItemProps {
  href?: string;
  icon: string;
  onClick?: () => void;
  title: string;
  subtitle?: string;
}

function Item({ href, icon, onClick, title, subtitle }: ItemProps) {
  const content = (
    <>
      <svg className="inline-block h-6 w-6 shrink-0">
        <use xlinkHref={`/img/icon-sprite.svg#${icon}`} />
      </svg>
      <span className="flex flex-col gap-y-0.5">
        <span className="font-bold text-blue-800">{title}</span>
        {subtitle && <span className="text-sm text-gray-600">{subtitle}</span>}
      </span>
    </>
  );
  return (
    <Menu.Item>
      {href ? (
        <ItemLink href={href}>{content}</ItemLink>
      ) : (
        <button className={itemClassNames} onClick={onClick} type="button">
          {content}
        </button>
      )}
    </Menu.Item>
  );
}

interface HeaderMenuProps {
  children: React.ReactElement<ItemProps>[];
  title: string;
  variant?: "default" | "blue";
}

const menuButtonClassNames = {
  default:
    "flex items-center rounded-full px-4 py-2 text-sm font-bold uppercase transition text-blue hover:text-green focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400 md:px-6",
  blue: "flex items-center px-4 py-2 text-sm font-bold uppercase transition rounded-full md:px-6 text-white bg-blue-700 hover:bg-blue-800 focus-visible:ring focus-visible:ring-blue-400 focus-visible:outline-none",
};

const openedMenuButtonClassNames = {
  default: "text-green",
  blue: "",
};

function HeaderMenu({ children, title, variant = "default" }: HeaderMenuProps) {
  return (
    <Menu as="li" className="md:relative">
      <Menu.Button className={menuButtonClassNames[variant]}>
        {({ open }) => (
          <>
            <span
              className={`whitespace-nowrap ${
                open ? openedMenuButtonClassNames[variant] : ""
              }`}
            >
              {title}
            </span>
            <svg
              className={`inline-block h-5 w-5 shrink-0 transition-transform ${
                open ? `rotate-180 ${openedMenuButtonClassNames[variant]}` : ""
              }`}
            >
              <use xlinkHref="/img/icon-sprite.svg#chevron-down" />
            </svg>
          </>
        )}
      </Menu.Button>
      <Menu.Items
        as="ul"
        className="absolute top-full right-0 left-0 mt-1 flex flex-col gap-y-px overflow-hidden rounded-sm bg-white shadow-md shadow-gray-500/20 md:right-0 md:left-auto md:w-[300px]"
      >
        {children}
      </Menu.Items>
    </Menu>
  );
}

HeaderMenu.Item = Item;

export default HeaderMenu;
