declare interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  appendClassName?: string;
}

export default function Input({ label, appendClassName, ...props }: Props) {
  let className =
    "block rounded-md border-gray-300 text-gray-500 shadow-sm read-only:bg-gray-100 read-only:opacity-50 focus:border-blue-400 focus:ring-2 focus:ring-blue-400 sm:text-sm";

  if (props.type === "checkbox" || props.type === "radio") {
    className =
      "w-4 h-4 rounded-md border-gray-300  shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 cursor-pointer";
  }

  if (appendClassName) {
    className += ` ${appendClassName}`;
  }

  if (!label) {
    return <input type="text" className={className} {...props} />;
  }

  return (
    <label className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
      <div className="w-24 text-sm font-bold text-blue-700 sm:mt-px sm:pt-2">
        {label}
      </div>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <input type="text" className={"w-full " + className} {...props} />
      </div>
    </label>
  );
}
