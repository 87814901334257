import { useRouter } from "next/router";
import useSWR from "swr";

import type { User } from "../types";

const backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;

export function useUserLogout() {
  return () => {
    const next = encodeURIComponent(window.location.origin);
    window.location.href = `${backendHost}/end-session?next=${next}`;
  };
}

export function useUserRedirect() {
  const { error } = useSWR("user");
  const router = useRouter();

  if (error) {
    router.push("/");
  }
}

export default function useUser() {
  return useSWR<User>("user", { shouldRetryOnError: false });
}
