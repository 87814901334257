import Footer from "./Footer";
import Header from "./Header";

import type { ApiFrontmatter } from "../types";

interface Props {
  apis: ApiFrontmatter[];
  children: React.ReactNode;
}

export default function Layout({ apis, children }: Props) {
  return (
    <div className="font-sans text-base tracking-normal text-gray-800">
      <Header apis={apis} />
      {children}
      <Footer />
    </div>
  );
}
