import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import HeaderMenu from "./HeaderMenu";
import useUser, { useUserLogout } from "../utils/useUser";

import type { ApiFrontmatter } from "../types";

const backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;

interface HeaderNavLink {
  href: string;
  label: string;
}

function HeaderNavLink({ href, label }: HeaderNavLink) {
  const { asPath } = useRouter();
  return (
    <li>
      <Link href={href} 
          aria-current={asPath === href ? "page" : undefined}
          className={`flex items-center whitespace-nowrap rounded-full px-4 py-2 text-sm font-bold uppercase text-blue transition hover:text-green focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400 md:px-6 ${
              asPath === `/${href}` ? "text-green" : ""
          }`}
        >
          {label} 
      </Link>
    </li>
  );
}

export default function Header({ apis }: { apis: ApiFrontmatter[] }) {
  const { asPath } = useRouter();
  const [currentLocation, setCurrentLocation] = useState("false");
  const { data: user } = useUser();
  const userLogout = useUserLogout();

  useEffect(() => setCurrentLocation(window.location.href), []);

  return (
    <header className="w-full bg-white ">
      <div className="fluid-container py-6">
        <div className="flex flex-col items-center justify-between gap-y-4 md:flex-row">
          <Link href="/" 
              aria-current={asPath === "/" ? "page" : undefined}
              className="block w-[180px] focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400 lg:w-[200px]"
            >
              <img
                src="/img/logo.svg"
                className="block"
                width="228"
                height="62"
                alt="geOps Logo"
              />
          </Link>
          <nav className="relative z-10 w-full justify-center md:w-auto">
            <ul className="z-10 flex flex-wrap justify-center">
              <HeaderNavLink href="/pricing" label="Pricing" />
              <HeaderNavLink href="/guides" label="Guides"/>
              <HeaderMenu title="API Docs">
                {apis.map((api) => (
                  <HeaderMenu.Item
                    key={api.slug}
                    href={`/apis/${api.slug}`}
                    icon={api.icon}
                    title={api.title}
                    subtitle={api.subtitle}
                  />
                ))}
              </HeaderMenu>
              {user?.email ? (
                <HeaderMenu title="Account" variant="blue">
                  <HeaderMenu.Item
                    href="/account"
                    icon="profile"
                    title={
                      user.first_name && user.last_name
                        ? `${user.first_name} ${user.last_name}`
                        : user.nickname
                    }
                    subtitle="View or change your personal data"
                  />
                  {user?.is_admin ? (
                    <HeaderMenu.Item
                      href="/account/keys"
                      icon="key"
                      title="Developers list"
                      subtitle="Manage developers keys to access our services"
                    />
                  ) : <></>}
                  {user?.email ? (
                    <HeaderMenu.Item
                      href={'/account/developers/' + window.btoa(user.email)}
                      icon="key"
                      title="Keys"
                      subtitle="Manage your keys to access our services"
                    />
                  ) : <></>}
                  <HeaderMenu.Item
                    onClick={() => userLogout()}
                    icon="sign-out"
                    title="Signout"
                  />
                </HeaderMenu>
              ) : (
                <li className="ml-1">
                  <a
                    href={`${backendHost}/login?next=${currentLocation}`}
                    className="flex items-center rounded-full bg-blue-100 px-4 py-2 text-sm font-bold uppercase text-blue transition hover:bg-blue-800 hover:text-white focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400 md:px-6"
                  >
                    <span className="whitespace-nowrap">Login</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
